import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { LineWrapper } from "../global/common/containers"

import { GalleryInner } from "../global/gallery/common"
import ClassicImage from "../global/gallery/ClassicImage"
import GreenImage from "../global/gallery/GreenImage"
import DoubleDescription from "../global/gallery/DoubleDescription"

import DescriptionsLightbox from "../global/gallery/DescriptionsLightbox"

const GalleryWrapper = styled(LineWrapper)`
  padding-top: var(--section-spacing);
  padding-bottom: calc(var(--section-spacing) * 0.75);

  z-index: 9;
`

const Gallery = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isLightboxVisible, setLightboxVisibility] = useState(false)
  const [
    galleryPositionAndScrollBehavior,
    getGalleryPositionAndScrollBehavior,
  ] = useState({})

  const galleryEl = useRef(null)

  const title = data.aboutGalleryTitle
  const description = data.aboutGallerySubtitle
  const buttonText = data.aboutGalleryButtonText

  const gallery = data.aboutGallery

  useEffect(() => {
    const updateGalleryPositionAndScrollBehaviorOnWindowResize = () => {
      const galleryPosition = galleryEl.current.getBoundingClientRect()

      const galleryLeftPosition = galleryPosition.left + window.scrollX
      const galleryTopPosition = galleryPosition.top + window.scrollY

      getGalleryPositionAndScrollBehavior({
        left: galleryLeftPosition,
        top: galleryTopPosition,
        behavior: "smooth",
      })
    }

    window.addEventListener(
      "resize",
      updateGalleryPositionAndScrollBehaviorOnWindowResize
    )
    updateGalleryPositionAndScrollBehaviorOnWindowResize()
  }, [])

  const handleImageClick = (event, i) => {
    event.preventDefault()

    window.scrollTo(galleryPositionAndScrollBehavior)

    setLightboxVisibility(true)
    setCurrentSlide(i)
  }

  return (
    <GalleryWrapper ref={galleryEl}>
      <GalleryInner className="with-double-description">
        {gallery &&
          gallery.map((galleryItem, i) => {
            const id = `gallery-item-${i}`

            const itemData = {
              url:
                galleryItem.aboutGalleryImage.localFile.childImageSharp.gatsbyImageData,
              fullUrl: galleryItem.aboutGalleryImage.localFile.publicURL,
              alt: galleryItem.aboutGalleryImage.altText,
            }

            const getGalleryElement = elementType => {
              switch (elementType) {
                case "doubleDescriptionAndImage":
                  return (
                    <React.Fragment key={`with-description-${id}`}>
                      <DoubleDescription
                        title={title}
                        description={description}
                        buttonText={buttonText}
                        handleShuffleLinkClick={event =>
                          handleImageClick(
                            event,
                            Math.random() * (gallery.length - 1) + 1
                          )
                        }
                      />
                      <ClassicImage
                        onImageLinkClick={event => handleImageClick(event, i)}
                        {...itemData}
                      />
                    </React.Fragment>
                  )
                case "greenImage":
                  return (
                    <GreenImage
                      onImageLinkClick={event => handleImageClick(event, i)}
                      key={`small-${id}`}
                      {...itemData}
                    />
                  )
                default:
                  return (
                    <ClassicImage
                      onImageLinkClick={event => handleImageClick(event, i)}
                      key={`small-${id}`}
                      {...itemData}
                    />
                  )
              }
            }

            let elementType

            if (i === 3) {
              elementType = "doubleDescriptionAndImage"
            } else if (i === 1 || i === 4 || i === 8) {
              elementType = "greenImage"
            } else {
              elementType = "classicImage"
            }

            return getGalleryElement(elementType)
          })}
      </GalleryInner>
      <DescriptionsLightbox
        slides={gallery}
        currentSlide={currentSlide}
        isLightboxVisible={isLightboxVisible}
        setLightboxVisibility={setLightboxVisibility}
      />
    </GalleryWrapper>
  );
}

export default Gallery
